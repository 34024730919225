import { getAPIClient } from '@/lib/api';
import axios from 'axios';

export interface Geolocation {
  geolocation: {
    storeview: string;
    country: string;
  };
}

export const getGeolocationCountry = async (
  ip_address: string
): Promise<string> => {
  const url = `${process.env.NEXT_PUBLIC_UYN_BASE_URL}/wp-json/uyn/v1/geolocation`;

  try {
    const { data } = await axios.get<Geolocation>(url, {
      params: {
        ip_address
      }
    });

    const { geolocation } = data || {};
    const { country = '' } = geolocation || {};
    return country;
  } catch (error) {
    return '';
  }
};

export interface GeolocationV2 {
  country_code: string;
}
export const getGeolocationCountry_v2 = async (
  ip_address: string
): Promise<string> => {
  if (ip_address === '127.0.0.1' || ip_address === '::1') {
    ip_address = '62.149.188.200'; //sostituisco con un ip italiano (ip del sito di Aruba)
  }
  const url = `${process.env.IPSTACK_API_URL}/${ip_address}?access_key=${process.env.IPSTACK_API_KEY}&fields=country_code&output=json`;
  try {
    const { data } = await axios.get<GeolocationV2>(url, {});
    const { country_code = '' } = data || {};
    return country_code.toLowerCase();
  } catch (error) {
    return '';
  }
};

export const getGeolocationCountry_v2_fe = async (): Promise<string> => {
  const url = `${process.env.NEXT_PUBLIC_UYN_BASE_URL}/advanced-api/api/geolocation`;
  console.log(url);
  try {
    const { data } = await axios.get<GeolocationV2>(url, {});
    const { country_code = '' } = data || {};
    return country_code.toLowerCase();
  } catch (error) {
    return '';
  }
};

export const getMyIp = async (): Promise<string> => {
  try {
    const { data } = await axios.get<string>('https://api.ipify.org/');
    return data;
  } catch (error) {
    return '';
  }
};

export const fetchAdvisedUrl = async ({
  url,
  lang,
  country
}: {
  url: string | string[];
  lang: string;
  country: string;
}): Promise<any> => {
  const apiClient = getAPIClient({ country });

  try {
    const { data } = await apiClient.get('/wp-json/uyn/v1/translate-url', {
      params: { url: Array.isArray(url) ? `/${url.join('/')}` : url },
      headers: {
        'UYN-LANGUAGE': lang,
        'UYN-COUNTRY': country
      }
    });

    return data;
  } catch (error) {
    return '';
  }
};
