import clsx from 'clsx';

import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import Image from 'next/future/image';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import usePrice from '@/hooks/usePrice';
import LinkTo from '@/components/LinkTo';

import { IProductList } from '@/models';
import { useRouter } from 'next/router';
import { getLangCountry } from '@/helpers/languages';

interface Props {
  data: IProductList;
  _onClick?: () => void;
}

const ProductCard: React.FC<Props> = ({ data, _onClick }) => {
  const {
    name,
    price,
    slug,
    tags = [],
    short_description,
    gallery: { images = [] },
    made_in
  } = data;

  const { t } = useTranslation();
  const [formatPrice] = usePrice();

  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);

  const [previewImage, setPreviewImage] = useState(
    images[0] || '/img/placeholder.svg'
  );

  const iconsLeft = [];
  if (
    country.toLowerCase() == 'us' &&
    made_in &&
    made_in.toLowerCase() == 'us'
  ) {
    iconsLeft.push({
      icon: '/img/made-in-usa.png',
      name: 'Made in USA'
    });
  }

  const getProductPrice = () => {
    if (price.min && price.max) {
      if (Number(price.min) !== Number(price.max)) {
        return `${formatPrice(price.min)} - ${formatPrice(price.max)}`;
      }

      return formatPrice(price.min);
    } else if (price.from) {
      return `${t('STARTING_FROM')}${formatPrice(price.from)}`;
    } else if (!price.sale_price) {
      return formatPrice(price.price);
    } else {
      return (
        <>
          <span className="line-through	text-gray-400">
            {formatPrice(price.price)}
          </span>
          <span className="font-bold"> {formatPrice(price.sale_price)}</span>
        </>
      );
    }
  };

  const icons = filter(
    filter(
      [
        get(data, 'technology', null)!,
        get(data, 'material', null)!,
        get(data, 'award', null)!
      ],
      Boolean
    ),
    ({ icon }) => Boolean(icon)
  );

  let discountPercentage = 0;
  if (price.price && price.sale_price && price.sale_price < price.price) {
    discountPercentage = Math.round((1 - price.sale_price / price.price) * 100);
  }

  return (
    <li className="group flex flex-col space-y-1.5">
      <div className="relative">
        {!isEmpty(iconsLeft) && (
          <ul className="h-min absolute inset-0 flex-col items-start space-y-3 pointer-events-none p-1 xl:p-3 w-[40px] xl:w-full xl:flex">
            {iconsLeft.map((icon, idx) => (
              <li key={idx}>
                <Image
                  src={icon.icon}
                  alt={icon.name}
                  width={0}
                  height={0}
                  sizes="100vw"
                  className="w-16 h-auto object-cover"
                />
              </li>
            ))}
          </ul>
        )}
        <LinkTo
          href={slug}
          aria-label={name}
          className="cursor-pointer"
          onClick={_onClick}
        >
          <Image
            src={previewImage}
            alt={name}
            priority
            width={0}
            height={0}
            sizes="100vw"
            className="w-full h-auto object-cover aspect-[4/5]"
          />
        </LinkTo>

        {!isEmpty(icons) && (
          <ul className="hidden h-min absolute inset-0 flex-col items-end space-y-3 pointer-events-none p-3 xl:flex">
            {icons.map((icon, idx) => (
              <li key={idx}>
                <Image
                  src={icon.icon}
                  alt={icon.name}
                  width={0}
                  height={0}
                  sizes="100vw"
                  className="w-16 h-auto object-cover"
                />
              </li>
            ))}
          </ul>
        )}

        {(!isEmpty(tags) || discountPercentage > 0) && (
          <ul className="flex space-x-0.5 md:space-x-1">
            {discountPercentage > 0 && (
              <li
                key={'discount'}
                style={{ backgroundColor: '#CC0C39' }}
                className="text-xs md:text-base text-white px-3 py-1.5"
              >
                -{discountPercentage}%
              </li>
            )}
            {!isEmpty(tags) &&
              tags.map(tag => (
                <li
                  key={tag.label}
                  style={{ backgroundColor: tag.color }}
                  className="text-xs md:text-base text-white px-3 py-1.5"
                >
                  {tag.label}
                </li>
              ))}
          </ul>
        )}
      </div>

      <div className="flex-1 flex flex-col space-y-1.5 overflow-hidden">
        <h3
          className={clsx('text-xs md:text-base font-medium', {
            'group-hover:hidden': images.length > 1
          })}
        >
          <LinkTo href={slug} aria-label={name} onClick={_onClick}>
            {name}
          </LinkTo>
        </h3>

        {!isEmpty(images) && (
          <ul
            className={clsx('hidden overflow-x-auto space-x-3', {
              'group-hover:flex': images.length > 1
            })}
          >
            {images.map(
              (src, idx) =>
                idx < 4 && (
                  <li
                    key={idx}
                    className="cursor-pointer"
                    onMouseOver={() => setPreviewImage(src)}
                    onClick={() => setPreviewImage(src)}
                  >
                    <LinkTo href={slug} aria-label={name}>
                      <Image
                        src={src}
                        alt={name}
                        width={0}
                        height={0}
                        sizes="100vw"
                        onClick={_onClick}
                        className="min-w-[32px] h-auto object-cover md:min-w-[44px]"
                      />
                    </LinkTo>
                  </li>
                )
            )}
            {images.length > 4 && (
              <li
                key="over-4"
                className="cursor-pointer w-[32px] h-[32px] md:w-[44px] md:h-[44px] flex items-center justify-center bg-gray-200 text-gray-400 font-bold"
              >
                {`+${images.length - 4}`}
              </li>
            )}
          </ul>
        )}

        {short_description && (
          <p className="text-xs text-gray-400 md:text-base line-clamp-2">
            {short_description}
          </p>
        )}

        {images.length > 1 && (
          <p className="text-xs font-bold text-gray-800 md:text-sm group-hover:hidden">
            {images.length} {t('colors')}
          </p>
        )}

        <p className="text-xs md:text-base">{getProductPrice()}</p>
      </div>
    </li>
  );
};

export default ProductCard;
